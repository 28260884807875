<template></template>

<script>
  export default {
    created() {
      localStorage.clear();
      const domain = window.location.href.includes('indsiders.com');
      this.$cookies.remove('userInfo', null, domain ? 'indsiders.com' : null);
      window.location.href = `${this.$myIndLink}login`;
    }
  };
</script>

<style></style>
